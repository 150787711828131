import { useRef } from "react";
import Footer from "./components/Footer";
// import Line from "./components/LineContact";
import Main from "./components/Main";
import AboutUs from "./components/pages/AboutUs";
import BossMsg from "./components/pages/BossMsg";
import BreakWord from "./components/pages/BreakWord";
import ClientMsg from "./components/pages/ClientMsg";
import Introduce from "./components/pages/Introduce";
// import News from "./components/pages/News";
import Price from "./components/pages/Price";
import Ads1 from './components/pages/Ads1';
import Ads2 from './components/pages/Ads2';

export default function AppRoutes() {
  const upLab = useRef(null);
  const clients = useRef(null);
  const about = useRef(null);
  const boss = useRef(null);

  const setScrollPosition = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* <Line /> */}
      <Main
        setScrollPosition={setScrollPosition}
        upLab={upLab}
        clients={clients}
        about={about}
        boss={boss}
      />
      {/* <News /> */}
      <Introduce upLab={upLab} />
      <Ads1/>
      <BreakWord />
      <AboutUs about={about} />
      <ClientMsg clients={clients} />
      <Price />
      <BossMsg boss={boss} />
      <Ads2/>
      <Footer />
    </>
  );
}
