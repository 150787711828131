import React from "react";

const navigation = {
  main: [
    { name: "特定商取引法に基づく表記", href: "/legal-information" },
    { name: "利用規約", href: "/terms-of-service" },
    { name: "プライバシーポリシー", href: "/privacy-policy" },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-2 text-sm text-gray-500">
                株式会社 ADDIN | 東京オフィス
              </span>
            </div>
          </div>
          <br />
          〒150-0002 東京都港区浜松町２丁目２番１５号 浜松町ダイヤビル２Ｆ :
          03-6771-9342
        </p>
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-2 text-sm text-gray-500">
                INDIA Office | インドオフィス
              </span>
            </div>
          </div>
          <br />
          Office No.3, 2nd Floor, Aditya Centeegra, Opp Niranjan Hotel, Beside
          Mantri House, Dhyneshwar Paduka Chowk, FC Road, Deccan, Pune 411004 :
          +91 7821054373
        </p>

        <nav
          className="-mb-6 mt-12 columns-2 sm:flex sm:justify-center sm:space-x-12"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a
                href={item.href}
                className="text-sm leading-6 text-gray-500 hover:text-gray-900 underline underline-offset-2"
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>

        <p className="mt-12 text-center text-xs leading-5 text-gray-500">
          お問合せ先：事務局
          <br />
          <a
            className="underline underline-offset-2 hover:text-gray-900"
            href="mailto:up-lab@addin-global.com"
          >
            up-lab@addin-global.com
          </a>
        </p>
        <p className="mt-12 text-center text-xs leading-5 text-gray-500">
          &copy; All right reserved by ADDIN Co. 2024
        </p>
      </div>
    </footer>
  );
}
