import Logo from "../../assets/Images/black_logo.png";

export default function CHeader() {
  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Company</span>
            <img className="h-8 w-auto" src={Logo} alt="logo" />
          </a>
        </div>

        <div className="lg:flex lg:flex-1 lg:justify-end">
          <a href="/" className="text-sm font-semibold leading-6 text-gray-900">
            <span aria-hidden="true">&larr;</span> 戻る
          </a>
        </div>
      </nav>
    </header>
  );
}
