import { CheckIcon } from "@heroicons/react/20/solid";

const tiers = [
  {
    name: "インディペンデンス",
    subTitle: "授業実施期間 : 2ヶ月~",
    id: "tier-independent",
    href: "#",
    description: "自己理解の探求と新たな進路の探究",
    features: [
      "7セッション",
      "自己分析",
      "習慣の見直し",
      "モチベーションの核",
      "マインドブロック",
      "日本経済の学習",
      "年収UPの共通点",
    ],
    mostPopular: false,
  },
  {
    name: "プロフェッショナル",
    subTitle: "授業実施期間 : 4ヶ月~",
    id: "tier-professional",
    href: "#",
    description: "新たな事業を始めるためのアイデアを模索する転機",
    features: [
      "12セッション",
      "自己分析",
      "なぜなぜ分析",
      "マインドブロック",
      "モチベーションの核",
      "年収UPの共通点",
      "書類対策",
      "業界研究",
      "適正試験対策",
      "面接対策",
      "転職後サポート（条件有）",
    ],
    mostPopular: true,
  },
  {
    name: "エリート",
    subTitle: "授業実施期間 : 6ヶ月~",
    id: "tier-elite",
    href: "#",
    description:
      "従業員と起業家の思考プロセスの対比：リスク、決断、タイミング、資金活用",
    features: [
      "18セッション",
      "自己分析",
      "日本経済の学習",
      "事業計画立案",
      "お金、税務知識",
      "小さく立ち上げ検証",
      "集客ノウハウ・検証",
      "ホームページ作成（条件有）",
      "ロゴ作成（条件有）",
      "契約書・請求書",
      "行動計画",
    ],
    mostPopular: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Price() {
  return (
    <div className="bg-white pb-24 sm:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier, tierIdx) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? "lg:z-10 lg:rounded-b-none" : "lg:mt-8",
                tierIdx === 0 ? "lg:rounded-r-none" : "",
                tierIdx === tiers.length - 1 ? "lg:rounded-l-none" : "",
                "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10"
              )}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular ? "text-yellow-500" : "text-gray-900",
                      "text-lg font-semibold leading-8"
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-yellow-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-yellow-500">
                      一番人気
                    </p>
                  ) : null}
                </div>
                <h4 className="mt-4 text-sm leading-6 text-yellow-500">
                  {tier.subTitle}
                </h4>
                <p className="mt-4 text-sm leading-6 text-gray-600">
                  {tier.description}
                </p>

                <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-yellow-500"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-yellow-500 text-white shadow-sm hover:bg-yellow-400"
                    : "text-yellow-500 ring-1 ring-inset ring-yellow-400 hover:ring-yellow-300",
                  "mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                )}
              >
                より詳しく
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
